
.translog_section{
    display: flex;
    flex: 1 1 100%;
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
}
.translog_section .text_ln{
    font-size: 12px;
    width: 100%;
}
.translog_section .status_successful{
    color: #00A520;
    background-color: #E0F4E8;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.translog_section .status_failed{
    color: #FF0000;
    background-color: #F8E3E6;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.translog_hr{
    height: 1px;
    background-color: #D9D9D9;
    border: none;
}

.transaction_table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1em;
  }
  
  .transaction_table th, .transaction_table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .transaction_table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .status_successful {
    color: green;
  }
  
  .status_failed {
    color: red;
  }
  
  .translog_hr {
    margin: 0.5em 0;
    border: none;
    border-top: 1px solid #ddd;
  }
  