.fund-wallet-panel {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fund-wallet-form {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px 40px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.submit-btn {
    background-color: #1e88e5;
    color: white;
    font-weight: 600;
    padding: 12px 0;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #1565c0;
}

.wallet-otp-modal {
    z-index: 1060; 
}


.fund-wallet-container {
    padding: 20px;
    background-color: #f9f9f9; 
    border-radius: 10px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    height: auto;
}

.form-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333; 
}

.fund-wallet-form {
    display: flex;
    flex-direction: column;
}

.small-input {
    height: 40px; 
    font-size: 14px; 
}

.custom-button {
    background-color: #007bff; 
    color: white; 
    font-size: 16px; 
    padding: 10px; 
    border: none;
    border-radius: 5px; 
    transition: background-color 0.3s;
}

.custom-button:hover {
    background-color: #0056b3; 
}


.error-text {
    color: red;
    font-size: 12px;
}

.bank-details {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.account-number {
    display: flex;
    align-items: center;
}

.copy-button {
    background-color: #b20c02;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s;
    margin-left: 10px;
}

.copy-button:hover {
    background-color: #b20c02;
}

.copy-notification {
    margin-top: 10px;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border-radius: 4px;
    font-size: 0.9rem;
    text-align: center;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
}

.custom-modal-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050;
    width: 90%;
    max-width: 500px;
    padding: 1rem;
}

.custom-modal-content {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.custom-modal-header {
    padding: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.custom-modal-body {
    padding: 1.5rem;
}


.custom-modal-header .close {
    font-size: 1.5rem;
    color: #000;
    opacity: 0.7;
}


.custom-button-primary {
    background-color: #b20c02;
    color: #fff;
    border: none;
    padding: 0.75rem;
    border-radius: 8px;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.custom-button-primary:hover {
    background-color: #b20c02;
}


.custom-button-secondary {
    background-color: #f1f1f1;
    color: #333;
    border: none;
    padding: 0.75rem;
    border-radius: 8px;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.custom-button-secondary:hover {
    background-color: #ddd;
}

.payment-logo {
    height: 24px;
}


.alternative-options-divider {
    position: relative;
    margin: 1.5rem 0;
    font-size: 0.875rem;
    color: #666;
}

.alternative-options-divider:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 1px;
    background: #ddd;
    z-index: -1;
}

.alternative-options-divider span {
    background-color: #fff;
    padding: 0 0.5rem;
}

