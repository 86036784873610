
.cust_section{
    display: flex;
    flex: 1 1 100%;
    padding: 0 10px 0 10px;
    gap: 5px;
}
.cust_section:hover{
    background-color: #FCFCFD;
}
.cust_section .cust_text_ln{
    width: 100%;
    font-size: 16px;
    width: 100%;
}
.cust_section .cust_action_div{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.cust_action_div .delete_button{
    border: none;
    background-color: transparent;
    color: #EB5757;

}
.cust_action_div .edit_button{
    border: none;
    background-color: transparent;
    color: #2F80ED;
}
/* .cust_section .cust_status_successful{
    color: #00A520;
    background-color: #E0F4E8;
    border-radius: 5px;
    height: 20px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.cust_section .cust_status_failed{
    color: #FF0000;
    background-color: #F8E3E6;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;
} */