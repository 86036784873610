.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050; 
  }
  
  .otp-modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: 600;
  }
  
  .close {
    font-size: 24px;
    border: none;
    background: transparent;
    cursor: pointer;
  }
  
  .modal-body {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .modal-footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .btn {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
  }
  