.cta-banner {
    background: linear-gradient(135deg, #b20c02, #800d07);
    color: white;
    padding: 3rem 0;
}

.cta-banner h2 {
    font-weight: 700;
    color: #ffffff;
}

.cta-banner p {
    font-size: 1.25rem;
    color: #e0e0e0;
}

.cta-banner .btn-primary {
    background-color: #b20c02;
    border: none;
    padding: 0.75rem 2rem;
    font-size: 1.1rem;
    font-weight: 600;
    transition: background-color 0.3s;
}

.cta-banner .btn-primary:hover {
    background-color: #dc3c34;
}
