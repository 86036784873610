.phy_ver_super_contaner{
    margin: 80px 20% 80px 20%; 
    /* border-radius: 20px;
    padding: 40px;
    box-shadow: 0 0 10px gray;
    border: 1px solid #B20C02; */
 }
.phy_ver_hd_wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.phy_ver_hd_title{
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 500;
}
.phy_ver_hd_txt{
    margin-top: 0;
    font-size: 14px;
    font-weight: 500;
    color: #8C8CA1;
}
.phy_ver_main{
    display: flex;
    align-items: center;
    justify-content: center;   
}
.phy_ver_content_container{    
}
.phy_ver_action_button{
    background-color: #B20C02;
    width: 100%;
    border: none;
    border-radius: 10px;
    color: #FFFFFF;
    height: 48px;
}
.phy_ver_sec_title{
    font-size: 16px;
    font-weight: 600;
}
.content_upload_frm{
    background-color: #ECF1F4;
    width: 400px;
    padding: 20px;
    margin: 10px 0 10px 0px;
    border-radius: 10px;
}
.content_title{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
}
.content_input{
    margin-bottom: 10px;
    width: 97%;
    height: 48px;
}
.content_select_input{
    border: 1px solid #D9D9D9;
    margin-bottom: 10px;
    width: 100%;
    height: 48px;
    border-radius: 10px;
}
