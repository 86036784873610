.footer {
    background-image: linear-gradient(135deg, #b20c02 0%, #7a120d 100%);;
    color: #ddd; 
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-size: 0.9rem;
}

.footer h5 {
    font-size: 1.1rem;
    font-weight: 600;
    color: #f8f9fa;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.footer ul {
    padding: 0;
    list-style: none;
}

.footer ul li {
    margin-bottom: 0.6rem;
}

.footer ul li a {
    color: #ddd;
    text-decoration: none;
    transition: color 0.3s;
}

.footer ul li a:hover {
    color: #ed5555; 
    text-decoration: underline;
}

.footer p {
    margin: 0.4rem 0;
}

.footer a {
    color: #ddd;
    transition: color 0.3s;
}

.footer a:hover {
    color: #0d6efd;
    text-decoration: underline;
}


.footer .social-links a {
    color: #ddd;
    transition: color 0.3s;
    font-size: 1.3rem; 
}

.footer .social-links a:hover {
    color: #0d6efd;
}

.footer hr {
    border-color: rgba(255, 255, 255, 0.1);
}

.footer p.mb-0 {
    color: #aaa;
    font-size: 0.85rem;
    margin-top: 1rem;
}
