body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
}

.dashboard {
  font-size: 16px;
}

.bg-default-bg {
  background-color: #f9fafb;
}

.panel-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.panel-shadow-light {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.navbar {
  border-bottom: 1px solid #eaeaea;
}

.navbar-brand img {
  max-height: 50px;
}

.navbar-nav .nav-link {
  font-size: 14px;
  color: #333;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.active {
  color: #b20c02;
}

.menu-item {
  font-size: 14px;
  color: #555;
}

.menu-item.active,
.menu-item:hover {
  background-color: #b20c02;
  color: #fff;
}

.menu-icon {
  font-size: 20px;
}


.main-content {
  flex: 1;
  justify-content: center; 
  align-items: center;  
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card {
  border: none;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 18px;
  color: #333;
}

.card-text {
  font-size: 14px;
  color: #777;
}

.card-text small {
  font-size: 12px;
}

.section-divider-text p {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}


