/* Style for search input */
.transaction-search-input {
    width: 100%;
    padding: 8px;
    margin: 20px 0;
    font-size: 16px;
  }

  /* Pagination styles */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    list-style: none;
  }
  
  .pagination__link {
    padding: 8px 12px;
    margin: 0 4px;
    cursor: pointer;
    color: #007bff;
  }
  
  .pagination__link--active {
    background-color: #007bff;
    color: white;
    border-radius: 4px;
  }
  
  .pagination__link--disabled {
    color: #ddd;
    cursor: not-allowed;
  }
  