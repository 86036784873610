.dashboard {
    font-family: 'Roboto', sans-serif;
    background-color: #f4f7fc;
  }
  
  .container-fluid-custom {
    padding: 20px;
  }
  
  .dashboard-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  /* .main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  } */
  
  .send-money-content h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .send-money-nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .send-money-nav .send-money-link {
    text-decoration: none;
    padding: 10px 15px;
    background-color: #b20c02;
    color: #fff;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .send-money-nav .send-money-link:hover {
    background-color: #b20c02;
  }
  
  .send-money-transaction {
    margin-top: 20px;
  }

  .send-money-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .send-money-nav-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .send-money-nav-item {
    flex: 1 1 45%; 
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }
  
  .send-money-link {
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-decoration: none;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .send-money-nav-item {
      flex: 1 1 45%;
      margin-bottom: 10px;
    }
  }
  