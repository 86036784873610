.wallet-to-wallet-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.wallet-to-wallet-title {
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.wallet-to-wallet-form {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px 40px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.wallet-to-wallet-form .form-group {
  margin-bottom: 1.5rem;
}

.wallet-to-wallet-form .form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
}

.wallet-to-wallet-form .form-group input,
.wallet-to-wallet-form .form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  transition: border-color 0.2s ease-in-out;
}

.wallet-to-wallet-form .form-group input:focus,
.wallet-to-wallet-form .form-group textarea:focus {
  border-color: #b20c02;
}

.wallet-to-wallet-form .submit-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #b20c02;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.wallet-to-wallet-form .submit-button:hover {
  background-color: #b20c02;
}

.wallet-to-wallet-form .form-control {
  margin-bottom: 1rem;
}

.modal-header {
  background-color: #f8f9fa;
  color: #333;
}

.modal-header .btn-close {
    filter: none !important;
}

.modal-header .close:hover {
  color: #000;
}
