.transaction-details {
    padding: 20px;
    background-color: #f8f9fa; 
  }
  
  .transaction-details .card {
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .transaction-details .card-header {
    background-color: #ffffff;
    padding: 16px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  
  .transaction-details .card-header button {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .transaction-details .card-body {
    padding: 30px;
    background-color: #ffffff;
  }
  
  .transaction-details h5 {
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .transaction-details p {
    margin: 0;
    font-size: 16px;
    color: #666666;
  }
  
  .transaction-details strong {
    color: #000000;
  }
  
  .transaction-details .text-danger {
    color: #ff4d4f !important; 
  }
  
  .transaction-details .text-success {
    color: #28a745 !important; 
  }
  
  .transaction-details .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: #ffffff;
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
    text-align: center;
  }
  
  .transaction-details .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  .transaction-details hr {
    margin: 20px 0;
    border-top: 1px solid #e5e5e5;
  }
  
  .transaction-details h6 {
    font-weight: bold;
    color: #333333;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .transaction-details .card-body {
      padding: 20px;
    }
  
    .transaction-details h5 {
      font-size: 18px;
    }
  
    .transaction-details p {
      font-size: 14px;
    }
  
    .transaction-details .btn-primary {
      font-size: 14px;
      padding: 6px 16px;
    }
  }
  