/* styles/modal.css */
.modal_main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other elements */
  }
  
  .modal_content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .modal_header h2 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .modal_close {
    cursor: pointer;
    background: transparent;
    border: none;
    font-size: 1.25rem;
    color: #333;
  }
  
  .modal_close:hover {
    color: #b20c02;
  }
  
  .modal_body {
    margin-bottom: 20px;
  }
  
  .modal_body label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .modal_body input, .modal_body textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .modal_body textarea {
    resize: vertical;
    height: 100px;
  }
  
  .modal_footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal_footer button {
    background-color: #b20c02;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .modal_footer button:hover {
    background-color: #a00a01;
  }
  
  .modal_footer .cancel_button {
    background-color: #ddd;
    color: #333;
  }
  
  .modal_footer .cancel_button:hover {
    background-color: #bbb;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  