.wallet-summary-container {
    margin: 2rem auto;
    max-width: 900px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

.wallet-summary-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
}

.wallet-balance {
    text-align: center;
}

.wallet-balance h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #555;
}

.balance-amount {
    font-size: 2rem;
    color: #28a745;
    font-weight: bold;
}

.last-updated {
    font-size: 0.9rem;
    color: #888;
}

.transaction-table-container {
    margin-top: 20px;
}

.transaction-table-container table {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
}

.transaction-table-container th, .transaction-table-container td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.download-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.csv-button, .pdf-button {
    background-color: #c20c02;
    color: #fff;
    border: none;
    padding: 10px 10px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.csv-button:hover, .pdf-button:hover {
    background-color: #c20c02;
}

.csv-button svg, .pdf-button svg {
    margin-right: 5px;
}

.search-input {
    max-width: 300px;
    margin-right: 10px;
}

.pagination {
    display: flex;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.page-item {
    margin: 0 5px;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    color: #c20c02;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
}

.page-link:hover {
    z-index: 2;
    color: #c20c02;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.pagination .active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #c20c02;
    border-color: #c20c02;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}


@media (max-width: 768px) {
    .search-input {
        width: 100%;
        margin-bottom: 10px;
    }

    .download-buttons {
        margin-top: 10px;
    }
}

.table-responsive {
    overflow-x: auto;
}

