
.payouts_super_container1{
    padding: 20px;
}
.payouts_super_container_blur{
    -webkit-filter: blur(10px);
    filter: blur(10px);
}
.payouts_header_info{
}
.payouts_header_title{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
}
.payouts_desc_text{
    margin-top: 2px;
    margin-bottom: 30px;
}
.payouts_addNewpayoutsomer_btn{
    background-color: #B20C02;
    color: #FFFFFF;
    height: 48px;
    border: none;
    border-radius: 10px;
    float: right;
    width: 200px;
}
.payouts_search_bar_container{
    width: 50%;
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
}
.payouts_search_box{
    display: flex;
    gap: 10px;
    align-items: center;
    width: 70%;
    height: 48px;
    border-radius: 10px;
    font-size: 16px;
    box-shadow: 0 0 5px #D0D5DD;
    border: 2px solid #D0D5DD;
    padding-left: 10px;

}

#payouts_search_input{
    border: none;
    height: 100%;
    margin: 0;
    outline: none;
    scale: none;
    color: #667085;
    font-size: 16px;
}
.payouts_search_filter{
    height: 53px;
    border-radius: 10px;
    background-color: transparent;
    text-align: center;
    font-size: 16px;
    box-shadow: 0 0 4px #D0D5DD;
    border: 2px solid #D0D5DD;
    color: #667085;
}
.payouts_dashboard_container{
    display: flex;
    gap: 40px;
}
.payouts_dashboard_section{

}
.payouts_dashboard_section_title{
    font-size: 16px;
    font-weight: 600;
    color: #8C8CA1;
    margin: 0;
    margin-bottom: 10px;
}
.payouts_dashboad_text{
    font-size: 24px;
    font-weight: 600;
    color: #4A4A68;
    margin: 0;
}
.available_balance{
    color: #27AE60;
}
.payouts_header{
    display: flex;
    flex: 1 1 100%;
    padding: 0 10px 0 10px;
    gap: 5px;
}
.payouts_header .payouts_text{
    font-size: 16px;
    font-weight: 700;
    color: #9E9E9E;
    width: 100%;
}
.payouts_hr{
    height: 2px;
    background-color: #FCFCFD;
    border: none;
}
.payouts_section{
    display: flex;
    flex: 1 1 100%;
    padding: 0 10px 0 10px;
    gap: 5px;
}
.payouts_section .payouts_text_ln{
    width: 100%;
    font-size: 16px;
}
.payouts_section .payouts_action_div{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.payouts_action_div .delete_button{
    border: none;
    background-color: transparent;
    color: #EB5757;

}
.payouts_action_div .edit_button{
    border: none;
    background-color: transparent;
    color: #2F80ED;
}
.payouts_section .payouts_status_wrapper{
    width: 100%;
}
.payouts_section .payouts_status_pending{
    color: #4A4A68;
    background-color: #ECF1F4;
    border-radius: 5px;
    height: 20px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.payouts_section .payouts_status_paid{
    color: #27AE60;
    background-color: #DBFFEC;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;
}