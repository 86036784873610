.security-compliance-section {
    background-color: #f8f9fa;
    padding: 3rem 0;
}

.feature-card {
    background-color: #ffffff;
    border-radius: 0.5rem;
    transition: box-shadow 0.2s;
}

.feature-card:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
    color: #b20c02; 
    transition: transform 0.2s;
}

.feature-icon:hover {
    transform: scale(1.1);
}

.feature-card .card-title {
    font-size: 1.25rem;
    color: #333;
}

.feature-card .card-text {
    color: #666;
}
