.merch_sec{
    display: flex;
    flex: 1 1 100%;
    margin: 0;
    padding: 10px;
}
.gray{
    background-color: #F5F5F5;
}
.merch_txt{
    width: 100%;
    font-size: 13px;
    font-weight: 40;
    width: 100%;
    margin: 0;
}
.merch_action{
    background-color: #B20C02;
    margin: 0;
    padding: 5px;
    text-align: center;
    width: 60px;
    border-radius: 5px;
    color: #FFFFFF; 
}