.request-payout-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.request-payout-modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
}

.request-payout-modal-header {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}


.request-payout-form {
    display: flex;
    flex-direction: column;
}

.request-payout-input {
    margin-bottom: 15px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.request-payout-input:focus {
    border-color: #4caf50;
    outline: none;
}


.request-payout-button {
    background-color: #4caf50;
    color: white;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.request-payout-button:hover {
    background-color: #45a049;
}


.request-payout-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}


.payout-error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 15px;
    text-align: left;
}


.payout-success-message {
    color: green;
    font-size: 14px;
    margin-top: 15px;
    text-align: left;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modal_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal_content {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 30px;
    width: 400px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.modal_content h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.wallet_balance p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #444;
}

.input_group {
    margin-bottom: 20px;
}

.input_group label {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
    display: block;
}

.input_field {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.3s ease;
}

.input_field:focus {
    outline: none;
    border-color: #c20c02;
    box-shadow: 0 0 8px rgba(98, 0, 234, 0.2);
}

.button_group {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.submit_button, .close_button {
    width: 48%;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.submit_button {
    background-color: #c20c02;
    color: white;
}

.submit_button:hover {
    background-color: #c20c02;
    box-shadow: 0px 4px 10px rgba(98, 0, 234, 0.3);
}

.close_button {
    background-color: #f1f1f1;
    color: #333;
}

.close_button:hover {
    background-color: #e0e0e0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
