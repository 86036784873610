.donations-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
  }
  
  .page-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .search-box {
    width: 300px;
  }
  
  .recipient-table {
    margin-top: 20px;
  }
  
  .recipient-table th, .recipient-table td {
    text-align: center;
    vertical-align: middle;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .default-form-check .form-check-input {
    float:none;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #afb1b0;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

 .default-form-check .form-check-input:hover {
    border-color: #b20c02;
  }

 .default-form-check .form-check-input:checked {
    border-color: #b20c02;
    background-color: #b20c02;
  }