:root {
  --btn-bg-customer: #b20c02;
}
.language-switcher {
  background-color: var(--btn-bg-customer);
  border-radius: 3px;
  margin-left: 25px !important;
  padding: 5px;
}

.language-switcher .dropdown-toggle {
  background-color: #b20c02 !important;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 0;
}

.language-switcher .dropdown-toggle::after {
  color: white !important;
}

.language-switcher .dropdown-menu {
  background-color: #b20c02 !important;
  color: #ffffff;
}

.language-switcher .dropdown-menu:hover {
  color: #ffffff !important;
}

.language-switcher .dropdown-item {
  color: #ffffff;
}

.language-switcher .dropdown-item:hover {
  background-color: #b20c02 !important;
  color: #ffffff !important;
}

@media (max-width: 767px) {
  .language-switcher .dropdown-toggle {
    background-color: #b20c02 !important;
    padding: 5px 10px;
    font-size: 14px;
  }
  .language-switcher {
    margin: 10px 0 !important;
    background-color: #b20c02 !important;
    padding: 5px;
    width: 60px;
  }
}
