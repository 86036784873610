:root {
  --default-theme-color: #b20c02;
  --default-white-text-color: #fff;
  --default-text-color: #b20c02;
  --default-border-color: #b20c02;
}

.landing-page {
    background: linear-gradient(135deg, #f54ea2 0%, #ff7676 100%);
    color: white;
    padding: 100px 0;
    text-align: center;
  }

.navbar-nav .nav-link {
    color: #631f1f !important;
}

.landing-background {
  background-color: var(--default-background-color);
}
  
  .logo {
    max-width: 150px;
    margin-bottom: 30px;
  }
  
  .btn-group .btn {
    margin: 10px;
  }
  
  .display-4 {
    font-weight: bold;
  }
  
  .lead {
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 20px;
  }
  
.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 1;
}

.carousel-caption-custom {
  z-index: 2;
  top: 30%; 
  transform: translateY(20%);
  text-align: center;
}

.carousel-heading {
  font-size: 3.5rem;
  letter-spacing: -1px;
  font-weight: 800;
  margin-bottom: 30px;
}

.carousel-text {
  font-size: 1.2rem;
  letter-spacing: 1px;
  margin: 0 auto;
  margin-bottom: 30px;
  width: 550px;
  overflow-wrap: break-word;
  text-align: center;
}

.carousel-heading, .carousel-text {
  color: #d4cfcf;
}

.btn-custom {
  background-color: transparent;
  color: var(--default-text-color);
  border: 1px solid var(--default-border-color);
  border-radius: 50px;
  padding: 10px 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.btn-custom:hover {
  background-color: var(--default-theme-color);
  color: #fff;
  border: 1px solid var(--default-border-color);
}

.about-us-section {
  padding: 60px 0;
  background-color: #f9f9f9;
}
.feature-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}
.feature-list li {
  font-size: 1.1rem;
  color: #333;
  padding-left: 1.5em;
  text-indent: -1.5em;
  margin-bottom: 10px;
}
.feature-list li:before {
  content: "•";
  color: #b81717;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.testimonials-section {
  background-color: #f8f9fa;
  padding: 3rem 0;
}

.testimonials-section .card {
  background: #ffffff;
  border-radius: 10px;
  border: none;
}

.testimonials-section h2 {
  color: #333;
}

.testimonials-section .carousel-control-next-icon,
.testimonials-section .carousel-control-prev-icon {
  background-color: #333;
  border-radius: 50%;
}


@media (max-width: 767.98px) {
  .carousel-caption-custom {
    z-index: 2;
    top: 20%; 
    transform: translateY(20%);
    text-align: center;
  }
  .carousel-heading {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 15px;
  }
  .navbar-nav .nav-link {
    color: #631f1f !important;
    text-align: center; 
    padding: 8px 0;
  }
  .navbar-collapse {
    text-align: center;
  }
  .btn-custom {
    width: 100%;
    margin-bottom: 10px; 
  }
}


