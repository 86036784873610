.subscribers_view_screen{
    padding: 20px;
}
.subscribers_super_wrapper{
    display: flex;
    flex-direction: row;
}
.subscribers_header_info{
}
.subscribers_header_title{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
}
.subscribers_desc_text{
    margin-top: 2px;
    margin-bottom: 30px;
}
.subscribers_search_bar_container{
    width: 50%;
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
}
.subscribers_search_box{
    display: flex;
    gap: 10px;
    align-items: center;
    width: 70%;
    height: 48px;
    border-radius: 10px;
    font-size: 16px;
    box-shadow: 0 0 5px #D0D5DD;
    border: 2px solid #D0D5DD;
    padding-left: 10px;

}

#subscribers_search_input{
    border: none;
    height: 100%;
    margin: 0;
    outline: none;
    scale: none;
    color: #667085;
    font-size: 16px;
}
.subscribers_search_filter{
    height: 53px;
    border-radius: 10px;
    background-color: transparent;
    text-align: center;
    font-size: 16px;
    box-shadow: 0 0 4px #D0D5DD;
    border: 2px solid #D0D5DD;
    color: #667085;
}
.subscribers_container{
    width: 100%;
}
.subscribers_header{
    display: flex;
    flex: 1 1 100%;
    padding: 0 5px 0 10px;
    gap: 5px;
}
.subscribers_header .subscribers_text{
    font-size: 16px;
    font-weight: 700;
    color: #9E9E9E;
    width: 100%;
}
.subscribers_hr{
    height: 1px;
    background-color: #D9D9D9;
    border: none;
}
.subscribers_section{
    display: flex;
    flex: 1 1 100%;
    padding: 0 10px 0 10px;
    gap: 5px;
}
.subscribers_section:hover{
    background-color: #D9D9D9;
    scale: 1.005;
    transition: 1s;
}
.subscribers_section .subscribers_text_ln{
    width: 100%;
    font-size: 16px;
}
.subscribers_section .subscribers_status_successful{
    color: #00A520;
    background-color: #E0F4E8;
    border-radius: 5px;
    height: 20px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.subscribers_section .subscribers_status_failed{
    color: #FF0000;
    background-color: #F8E3E6;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;
}
.subs_info_container{
    width: 30%;
    border: 1px solid #D9D9D9;
    margin-top: 8px;
}
.subs_info_title{
    font-size: 16px;
    font-weight: 600;
    padding: 0 10px 0 10px;
}
.subs_header{
    display: flex;
    flex: 1 1 100%;
    padding: 0 10px 0 10px;
    gap: 5px;
}
.subs_text{
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    font-size: 14px;
}
.subscribers_section{
    display: flex;
    flex: 1 1 100%;
    padding: 0 10px 0 10px;
    gap: 5px;
}
.subs_text_ln{
    width: 100%;
    color: #4A4A68;
    font-size: 14px;
}