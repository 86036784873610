.navbar-bg {
  background-color: #fff !important; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  padding: 0.5rem 2rem !important;
  border: 0 solid white !important;
  border-radius: 50px;
  margin: 15px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
  width: calc(100% - 30px)
}

.navbar-dashboard-bg {
  background-color: #b20c02 !important; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  padding: 0.5rem 2rem !important;
  border: 0 solid #b20c02 !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
}

.navbar-brand img {
  max-height: 40px !important; 
}

.navbar-toggler {
  border: none !important;
  color: #fff !important; 
  font-size: 1.25rem !important;
  padding: 0.5rem 1rem !important;
}

.offcanvas {
  background-color: #b20c02 !important;
  color: #ffffff !important; 
}

.offcanvas-header {
  padding: 1rem;
  border-bottom: 1px solid #ffffff !important; 
}

.offcanvas-title {
  color: #ffffff !important; 
}

.btn-close {
  filter: invert(1) !important; 
}

.offcanvas-body {
  padding: 1rem;
  color: #ffffff !important; 
}

.navbar-nav {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.navbar-nav .nav-item {
  margin-right: 1rem;
}

.navbar-nav .nav-link {
  color: #ffffff; 
  padding: 0.5rem 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #ffebe6; 
  background-color: transparent; 
}

.navbar-nav .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff; 
  object-fit: cover;
}

.navbar-nav .dropdown-toggle {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: #fff !important;
}

.navbar-nav .add-icon {
  font-size: 1.25rem;
  color: #ffffff;
  margin-left: 0.5rem;
}

.navbar-nav .add-icon:hover {
  color: #ffebe6; 
}

.dropdown-menu.show {
  display: block;
  width: 230px;
  border-radius: 0;
  border: 1px solid #dee2e6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #b20c02 !important;
  padding: 12px;
}

.dropdown-item {
  font-size: 0.875rem;
  color: #fff;
  display: flex !important;
  align-items: center !important;
  padding: 0.625rem 1.25rem !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease, color 0.3s ease; 
}

.dropdown-item:hover {
  background-color: #f1f1f1;
  color: #b20c02;
}

.dropdown-item .add-icon-sm {
  color: #ffffff !important;
}

.dropdown-item:hover .add-icon-sm {
  color: #b20c02 !important;
}

.dropdown-item .icon {
  transition: color 0.3s ease;
}

.dropdown-item:hover .icon {
  color: #b20c02; 
}

.navbar-nav .search-input {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  color: #333;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-input::placeholder {
  color: #999; 
}

.language-switcher {
  margin-left: auto;
  margin-right: 1rem;
  color: #ffffff; 
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
