.wallet-converter-card {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

.current-balance, .exchange-rate, .converted-balance {
  margin-bottom: 15px;
  font-size: 1.1em;
  color: #666;
}

.currency-label {
  display: block;
  margin-bottom: 8px;
  font-size: 1em;
  color: #555;
}

.currency-select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1em;
}

.confirm-button {
  background-color: #b20c02;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
}

.confirm-button:disabled {
  background-color: #ccc;
}

.error-message {
  margin-top: 10px;
  color: red;
  font-size: 0.9em;
}
