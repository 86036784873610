#partners-integrations .partner-logo img {
    max-height: 80px;
    object-fit: contain;
    margin: 0 auto;
}
#partners-integrations .card {
    transition: transform 0.2s ease;
}
#partners-integrations .card:hover {
    transform: scale(1.05);
}
